import _ from 'lodash';

export default {
  name: 'form-select',
  props: ['value', 'label', 'rules', 'required', 'items', 'itemKey', 'itemValue', 'customProps'],

  data() {
    return {
      errors: [],
    };
  },
  created() {
    if (this.rules && this.rules.length) this.validate(this.rules, this.value);
  },
  computed: {
    formatedItems() {
      let result = [];

      if (this.items && this.items.length) {
        if (typeof this.items[0] === String) {
          result = _.map(this.items, item => ({ key: item }));
        } else {
          result = this.items;
        }
      }

      return result;
    },
    indicator() {
      return this.itemKey || 'key';
    },
    text() {
      return this.itemValue || this.itemKey || 'key';
    },
  },
  methods: {
    onEdit(val) {
      this.validate(this.rules, val);
      this.$emit('input', val);
    },

    async validate(rules, value) {
      this.errors = [];

      await Promise.all(
        _.map(rules, async validator => {
          const result = await validator(value);
          if (result !== true) this.errors.push(result);
        })
      );

      const isValid = this.errors.length === 0;
      this.$emit('valid', isValid);
    },
  },
};
